import React, { useEffect, useState } from 'react';

import { Grid, FormControl, InputLabel, TextField, Button, IconButton, Popover, Select, MenuItem } from '@mui/material';

import Loader from 'components/Loader';
import Api from 'api/Api';

import Clear from '@mui/icons-material/Clear';

function MsgForm (props){ 

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [templates, setTemplates] = useState();
    const [template, setTemplate] = useState('');

    const [course_title, setCourseTitle] = useState('');
    const [timing, setTiming] = useState('');

    const [course_title_error, setCourseTitleError] = useState(false);
    const [timing_error, setTimingError] = useState(false);

    const [batch_title, setBatchTitle] = useState('');
    const [slot_timing, setSlotTiming] = useState('');

    const [batch_title_error, setBatchTitleError] = useState(false);
    const [slot_timing_error, setSlotTimingError] = useState(false);

    const [date, setDate] = useState('');
    const [emi_amount, setEmiAmount] = useState('');
    const [contact, setContact] = useState('');

    const [date_error, setDateError] = useState(false);
    const [emi_amount_error, setEmiAmountError] = useState(false);
    const [contact_error, setContactError] = useState(false);

    const [conference_input, setConferenceInput] = useState('');
    const [session_input, setSessionInput] = useState('');
    const [alternate_contact, setAlternateContact] = useState('');

    const [conference_input_error, setConferenceInputError] = useState(false);
    const [session_input_error, setSessionInputError] = useState(false);
    const [alternate_contact_error, setAlternateContactError] = useState(false);

    const [branch, setBranch] = useState('');
    const [branch_error, setBranchError] = useState('');

    useEffect(() => {
        
        if(!loaded){
        
            setLoaded(true)

            if(props.account_token){
            
                loadSmsTemplates();
            }
        }
    });

    const loadSmsTemplates = function(){

        var formData = new FormData();

        formData.append('account_token', props.account_token);

        Api.post('general/sms_templates', formData, function(data){

            if(data.status){
            
                setTemplates(data.templates);
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }

    const resetForm = function() {
        
        setTemplate('');

        setCourseTitle('');
        setTiming('');
        
        setBatchTitle('');
        setSlotTiming('');
        
        setDate('');
        setEmiAmount('');
        setContact('');

        setConferenceInput('');
        setSessionInput('');
        setAlternateContact('');
    }

    const submit = function(event){

        event.preventDefault();

        let can_continue = true;

        if(template === 'enquiry_sms'){

            if(course_title === ''){

                setCourseTitleError(true);
                can_continue = false;
            }

            if(timing === ''){

                setTimingError(true);
                can_continue = false;
            }
        }

        if(template === 'class_schedule_sms'){

            if(batch_title === ''){

                setBatchTitleError(true);
                can_continue = false;
            }

            if(slot_timing === ''){

                setSlotTimingError(true);
                can_continue = false;
            }
        }

        if(template === 'due_emi_sms'){

            if(date === ''){

                setDateError(true);
                can_continue = false;
            }

            if(emi_amount === ''){

                setEmiAmount(true);
                can_continue = false;
            }
        }

        if(template === 'cscs_prep_course_sms'){

            if(alternate_contact === ''){

                setAlternateContactError(true);
                can_continue = false;
            }
        }

        if(template === 'cfp_level_one_sms'){

            if(conference_input === ''){

                setConferenceInputError(true);
                can_continue = false;
            }

            if(alternate_contact === ''){

                setAlternateContactError(true);
                can_continue = false;
            }
        }

        if(template === 'new_international_ace_ereps_personal_trainer_batch' || template === 'certified_fitness_trainer_batch' || template === 'diploma_orientation_template' || template === 'nutrition_template'){

            if(date === ''){

                setDateError(true);
                can_continue = false;
            }

            if(contact === ''){

                setContactError(true);
                can_continue = false;
            }
        }

        if(template === 'asia_edufit_summit_sms'){

            if(conference_input === ''){

                setConferenceInputError(true);
                can_continue = false;
            }

            if(session_input === ''){

                setSessionInputError(true);
                can_continue = false;
            }

            if(alternate_contact === ''){

                setAlternateContactError(true);
                can_continue = false;
            }
        }

        if(template === 'international_diploma_personal_training_nutrition'){

            if(contact === ''){

                setContactError(true);
                can_continue = false;
            }
        }

        if(template === 'trial_class_template' || template === 'enrollment_template' || template === 'nasm_template'){

            if(branch === ''){

                setBranchError(true)
                can_continue = false;
            }

            if(contact === ''){

                setContactError(true)
                can_continue = false;
            }
        }

        if(can_continue){

            var formData = new FormData();

            if(template === 'enquiry_sms'){

                formData.append('course_title', course_title);
                formData.append('timing', timing);
            }

            if(template === 'class_schedule_sms'){

                formData.append('batch_title', batch_title);
                formData.append('slot_timing', slot_timing);
            }

            if(template === 'due_emi_sms'){

                formData.append('date', date);
                formData.append('emi_amount', emi_amount);
            }

            if(template === 'ewms_sms'){

                formData.append('course_title', course_title);
                formData.append('timing', timing);
            }

            if(template === 'cscs_prep_course_sms'){

                formData.append('alternate_contact', alternate_contact);
            }

            if(template === 'cfp_level_one_sms'){

                formData.append('conference_input', conference_input);
                formData.append('alternate_contact', alternate_contact);
            }

            if((template === 'new_international_ace_ereps_personal_trainer_batch' || template === 'certified_fitness_trainer_batch' || template === 'diploma_orientation_template' || template === 'nutrition_template')){

                formData.append('date', date);
                formData.append('contact', contact);
            }

            if(template === 'asia_edufit_summit_sms'){

                formData.append('conference_input', conference_input);
                formData.append('session_input', session_input);
                formData.append('alternate_contact', alternate_contact);
            }

            if(template === 'international_diploma_personal_training_nutrition'){

                formData.append('contact', contact);
            }

            if(template === 'trial_class_template' || template === 'enrollment_template' || template === 'nasm_template'){

                formData.append('branch', branch);
                formData.append('contact', contact);
            }

            formData.append('template', template);

            formData.append('account_token', props.account_token);
            
            if(props.source){

                formData.append('source', props.source);
            }

            if(props.lead_id){

                formData.append('lead_id', props.lead_id);
            }

            if(props.student_id){

                formData.append('student_id', props.student_id);
            }

            if(props.select_all){

                formData.append('select_all', Boolean(props.select_all));

                if(Object.keys(props.filters).length > 0){

                    formData.append('search', JSON.stringify(props.filters));
                }
            }else{

                if(props.bulk_mobile_numbers && props.bulk_mobile_numbers.length > 0){

                    formData.append('mobiles', JSON.stringify(props.bulk_mobile_numbers));
                }else{
    
                    formData.append('mobile', props.lead.mobile_number);
                }
            }

            setLoading(true);

            Api.post('misc/send_message', formData, function(data){

                setLoading(false);

                if(data.status == true){
                
                    resetForm();
                    props.setSuccessMessage(data.message);

                    setLoaded(false);
                    props.onSuccess();
                }else{

                    if(data.code && data.code == 'no_account'){

                        window.location = Api.server_url + 'logout';
                    }else{

                        props.setErrorMessage(data.message);
                    }
                    
                }
            });
        }
    }

    const templateTitle = function(field){

        let _templates = templates;

        if(_templates.length > 0){

            let _selected_template = _templates.find(row => row.key === template);

            if(_selected_template){

                let text = _selected_template[field];

                if(field === 'template' && template === 'enquiry_sms'){

                    text = text.replace('[course_title]', course_title);
                    text = text.replace('[timing]', timing);
                }

                if(field === 'template' && template === 'class_schedule_sms'){

                    text = text.replace('[batch_title]', batch_title);
                    text = text.replace('[slot_timing]', slot_timing);
                }

                if(field === 'template' && template === 'due_emi_sms'){

                    text = text.replace('[date]', date);
                    text = text.replace('[emi_amount]', emi_amount);
                }

                if(field === 'template' && template === 'ewms_sms'){

                    text = text.replace('[course]', course_title);
                    text = text.replace('[next_batch]', timing);
                }

                if(field === 'template' && template === 'cscs_prep_course_sms'){

                    text = text.replace('[alternate_contact]', alternate_contact);
                }

                if(field === 'template' && template === 'cfp_level_one_sms'){

                    text = text.replace('[conference_input]', conference_input);
                    text = text.replace('[alternate_contact]', alternate_contact);
                }

                if(field === 'template' && (template === 'new_international_ace_ereps_personal_trainer_batch' || template === 'certified_fitness_trainer_batch' || template === 'diploma_orientation_template' || template === 'nutrition_template')){

                    text = text.replace('[batch_date]', date);
                    text = text.replace('[contact]', contact);
                }

                if(field === 'template' && template === 'asia_edufit_summit_sms'){

                    text = text.replace('[conference_input]', conference_input);
                    text = text.replace('[session_input]', session_input);
                    text = text.replace('[alternate_contact]', alternate_contact);
                }

                if(field === 'template' && template === 'international_diploma_personal_training_nutrition'){

                    text = text.replace('[contact]', contact);
                }

                if(field === 'template' && (template === 'trial_class_template' || template === 'enrollment_template' || template === 'nasm_template')){

                    text = text.replace('[branch]', branch);
                    text = text.replace('[contact]', contact);
                }

                return text;
            }
        }
    }

    return (

        <Popover
            open={props.anchorEl ? true : false}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <div className="popover-confirm" style={{width:600}}>

                <div className="header space-between" style={{width: 'auto'}}>
                    <strong>{props.title}</strong>

                    <IconButton size="small" onClick={() => {

                            setLoaded(false);
                            resetForm();
                            props.onCancel();
                        }}>
                        <Clear />
                    </IconButton>
                </div>
                <div className="content" style={{padding:20}}>
        
                    <div style={{position:'relative'}}>

                        <Grid container spacing={3}>
                        
                            <Grid item xs={6}>

                                <FormControl fullWidth>

                                    <InputLabel>Select Template</InputLabel>

                                    <Select
                                        value={template}
                                        onChange={(event) => {

                                            setTemplate(event.target.value)
                                        }}
                                        label="Select Template"
                                    >
                                        <MenuItem value=""><em>Choose Template</em></MenuItem>

                                        {templates && templates.length > 0
                                            ?
                                                templates.map((_template) => {

                                                    return <MenuItem key={'sms_'+_template.key} value={_template.key}>{_template.title}</MenuItem>
                                                })
                                            :
                                                null
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                            
                        {template !== '' &&

                            <form className="form" name="login_form" onSubmit={submit}>
    
                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={12}>
                                            
                                        <FormControl fullWidth={true}>

                                            <strong className="fs-12">Template</strong>
                                            <span className="fs-12">{templateTitle('title')}</span>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                            
                                        <FormControl fullWidth={true}>

                                            <strong className="fs-12">Preview</strong>
                                            <span className="fs-12">{templateTitle('template')}</span>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {template === 'enquiry_sms' &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={course_title_error}
                                                    value={course_title}
                                                    name="course_title"
                                                    label="Course Title *"
                                                    helperText={course_title_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setCourseTitle(e.target.value);
                                                        setCourseTitleError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={timing_error}
                                                    value={timing}
                                                    name="timing"
                                                    label="Course Timing *"
                                                    helperText={timing_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setTiming(e.target.value);
                                                        setTimingError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {template === 'class_schedule_sms' &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={batch_title_error}
                                                    value={batch_title}
                                                    name="batch_title"
                                                    label="Batch Title *"
                                                    helperText={batch_title_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setBatchTitle(e.target.value);
                                                        setBatchTitleError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={slot_timing_error}
                                                    value={slot_timing}
                                                    name="slot_timing"
                                                    label="Slot Timing *"
                                                    helperText={slot_timing_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setSlotTiming(e.target.value);
                                                        setSlotTimingError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {template === 'due_emi_sms' &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={date_error}
                                                    value={date}
                                                    name="date"
                                                    label="Date *"
                                                    helperText={date_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setDate(e.target.value);
                                                        setDateError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={emi_amount_error}
                                                    value={emi_amount}
                                                    name="emi_amount"
                                                    label="EMI Amount *"
                                                    helperText={emi_amount_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setEmiAmount(e.target.value);
                                                        setEmiAmountError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {template === 'ewms_sms' &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={course_title_error}
                                                    value={course_title}
                                                    name="course_title"
                                                    label="Course Title *"
                                                    helperText={course_title_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setCourseTitle(e.target.value);
                                                        setCourseTitleError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={timing_error}
                                                    value={timing}
                                                    name="timing"
                                                    label="Next Batch Time *"
                                                    helperText={timing_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setTiming(e.target.value);
                                                        setTimingError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {template === 'cscs_prep_course_sms' &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={alternate_contact_error}
                                                    value={alternate_contact}
                                                    name="alternate_contact"
                                                    label="Alternate Contact *"
                                                    helperText={alternate_contact_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setAlternateContact(e.target.value);
                                                        setAlternateContactError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {template === 'cfp_level_one_sms' &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={conference_input_error}
                                                    value={conference_input}
                                                    name="conference_input"
                                                    label="Conference Input *"
                                                    helperText={conference_input_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setConferenceInput(e.target.value);
                                                        setConferenceInputError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={alternate_contact_error}
                                                    value={alternate_contact}
                                                    name="alternate_contact"
                                                    label="Alternate Contact *"
                                                    helperText={alternate_contact_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setAlternateContact(e.target.value);
                                                        setAlternateContactError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {template === 'asia_edufit_summit_sms' &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={conference_input_error}
                                                    value={conference_input}
                                                    name="conference_input"
                                                    label="Conference Input *"
                                                    helperText={conference_input_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setConferenceInput(e.target.value);
                                                        setConferenceInputError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={session_input_error}
                                                    value={session_input}
                                                    name="session_input"
                                                    label="Session Input *"
                                                    helperText={session_input_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setSessionInput(e.target.value);
                                                        setSessionInputError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={alternate_contact_error}
                                                    value={alternate_contact}
                                                    name="alternate_contact"
                                                    label="Alternate Contact *"
                                                    helperText={alternate_contact_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setAlternateContact(e.target.value);
                                                        setAlternateContactError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {(template === 'new_international_ace_ereps_personal_trainer_batch' || template === 'certified_fitness_trainer_batch' || template === 'diploma_orientation_template' || template === 'nutrition_template') &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={date_error}
                                                    value={date}
                                                    name="batch_date"
                                                    label="Batch Date / Time *"
                                                    helperText={date_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setDate(e.target.value);
                                                        setDateError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={contact_error}
                                                    value={contact}
                                                    name="contact"
                                                    label="Contact *"
                                                    helperText={contact_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setContact(e.target.value);
                                                        setContactError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {(template === 'international_diploma_personal_training_nutrition') &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={contact_error}
                                                    value={contact}
                                                    name="contact"
                                                    label="Contact *"
                                                    helperText={contact_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setContact(e.target.value);
                                                        setContactError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                {(template === 'trial_class_template' || template === 'enrollment_template' || template === 'nasm_template') &&

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={branch_error}
                                                    value={branch}
                                                    name="branch"
                                                    label="Branch *"
                                                    helperText={branch_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setBranch(e.target.value);
                                                        setBranchError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={contact_error}
                                                    value={contact}
                                                    name="contact"
                                                    label="Contact *"
                                                    helperText={contact_error ? 'This is required!' : ''}
                                                    onChange={(e) => {

                                                        setContact(e.target.value);
                                                        setContactError(false)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={12}>

                                        <div className="action-sm">
                                        
                                            <Button color="primary" size="small" variant="text" type="button" onClick={() => {

                                                setCourseTitleError(false);
                                                setTimingError(false)
                                                setLoaded(false);

                                                props.onCancel()
                                            }}>Cancel</Button>
                                            <Button color="primary" size="small" variant="contained" type="submit">Send</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        }

                        <Loader loading={loading} />
                    </div>
                </div>
            </div>
        </Popover>
    )
}

export default MsgForm;