import React, { Component } from 'react';

import { Container, Grid, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { connect } from 'react-redux';
import {User} from 'actions/user';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';

import Api from 'api/Api';

class Main extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,

            picture: null,
            preview_image: null,

            account_token: false,

            roles: false,

            flash_error_message: '',
            flash_success_message: ''
        }

        this.imageRef = null;
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');
        var account_token = localStorage.getItem('account_token');
        var role = localStorage.getItem('role');
        
        if(account_token){
            this.setState({account_token: account_token});
        }

        if(user){

            var _user = JSON.parse(user);
            this.setState({user: _user});
            
            var _role = JSON.parse(role);
            this.setState({role: _role});
        }

        const flash_success_message = localStorage.getItem('flash_success_message');
        const flash_error_message = localStorage.getItem('flash_error_message');

        if(flash_success_message){

            this.setState({flash_success_message: flash_success_message})
            localStorage.removeItem('flash_success_message');
        }

        if(flash_error_message){

            this.setState({flash_error_message: flash_error_message})
            localStorage.removeItem('flash_error_message');
        }
    }

    render () {

        
        return (

            <Container maxWidth={false} disableGutters={true}>
                
                <Grid container className={"full-grid"} alignItems="stretch" direction="row" justifyContent="flex-start">
                
                    <Grid item xs={12} className="main-col">
                        
                        <div className="main" id="main_page_wrapper">

                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={this.state.flash_success_message !== '' ? true : false}
                                autoHideDuration={5000}
                                key={this.state.flash_success_message}
                                onClose={() => {

                                    this.setState({flash_success_message: ''})
                                }}
                            >
                                <MuiAlert elevation={6} variant="filled" severity="success">{this.state.flash_success_message}</MuiAlert>
                            </Snackbar>

                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={this.state.flash_error_message !== '' ? true : false}
                                autoHideDuration={5000}
                                key={this.state.flash_error_message}
                                onClose={() => {

                                    this.setState({flash_error_message: ''})
                                }}
                            >
                                <MuiAlert elevation={6} variant="filled" severity="error">{this.state.flash_error_message}</MuiAlert>
                            </Snackbar>
                        
                            <AppHeader
                                success_message={this.props.success_message}
                                error_message={this.props.error_message}
                                active_link={this.props.active_link}
                                page={this.props.page}
                            />

                            <div className={"main-wrapper " + (this.props.format && this.props.format == 'center' ? 'center' : '')}>
                            
                                <Grid container spacing={0}>

                                    <Grid item xs={12} sm={12}>

                                        {this.props.title || this.props.title_action
                                            ?

                                                <div className="main-title-block">
                                                    {this.props.title &&

                                                        <h1>{this.props.title}</h1>
                                                    }

                                                    {this.props.title_action &&
                                                    
                                                        this.props.title_action
                                                    }
                                                </div>
                                            :
                                                null
                                        }
                                    </Grid>
                                </Grid>

                                {this.props.children}
                            </div>

                            <AppFooter />
                        </div>
                    </Grid>
                </Grid>
            </Container>  
        )
    }

    uploadProfileImgae = async (croppedImage) => {

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('tmp_file_name', croppedImage);
        formData.append('upload_dir', 'restaurant_gallery/');

        this.setState({image_uploading: true});

        this.setState({
            temp_profile_image: null,
        });

        var that = this;

        Api.post('clients/profile/update_profile_photo', formData, function(data){

            that.setState({picture: null, preview_image: null, logo_image_loading: false, main_img_loading: false, croppedImageUrl: null, croppedImage: null, image_uploading: false});

            if(data.status){

                that.setState({user: data.user});
                localStorage.setItem('user', JSON.stringify(data.user));
                that.props.User(data.user);
            }
        });
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default connect(mapStateToProps, { User } )(Main);